import Popup from 'reactjs-popup';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-link.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icons/telegram.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import styles from './ShareButton.module.css';

const contentStyle = { borderRadius: 8, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' };
const arrowStyle = { display: 'none' };

type ShareButtonProps = {
  title?: string;
  url?: string;
}

export default function ShareButton({ title, url }: ShareButtonProps) {
  const intl = useIntl();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url || '');
  };

  return (
    <Popup
      {...{ contentStyle, arrowStyle }}
      trigger={() => (
        <Button>
          <FormattedMessage id="constants.buttons.share.label" />
          <ShareIcon />
        </Button>
      )}
      position={['top center', 'top right', 'top left']}
      keepTooltipInside="body"
      closeOnDocumentClick
    >
      <h6 className={styles.title}>
        <FormattedMessage id="constants.share.label" />
      </h6>
      <div className={styles.list}>
        <a
          href={`"whatsapp://send?text=${url}"`}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.item}
        >
          <FormattedMessage id="constants.share-in-whatsapp.label" />
          <WhatsappIcon className={styles.icon} />
        </a>
        <a
          href={`https://t.me/share/url?url=${url}&text=${title ? intl.messages[title] : ''}`}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.item}
        >
          <FormattedMessage id="constants.share-in-telegram.label" />
          <TelegramIcon className={styles.icon} />
        </a>
        <div className={styles.item} onClick={handleCopyLink}>
          <FormattedMessage id="constants.copy-link.label" />
          <CopyIcon className={styles.icon} />
        </div>
      </div>
    </Popup>
  );
}