import { FormattedMessage } from 'react-intl';
import { VideoData } from '../../../constants';
import { MessagesType } from '../../../App';
import Card from '../../Card';
import Button from '../../Button';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import ShareButton from '../../ShareButton';
import TextWrapper from '../../TextWrapper';
import poster from '../../../assets/images/whats-in-it-poster.png';
import pipe from '../../../assets/images/pipes/pipe-1.svg';
import pipeAr from '../../../assets/images/pipes/pipe-1-ar.svg';
import pipe2 from '../../../assets/images/pipes/pipe-2.svg';
import styles from './WhatsInIt.module.css';

type WhatsInItProps = {
  lang: MessagesType;
  data: VideoData;
  onOpenVideoModal: () => void;
}

export default function WhatsInIt({ lang, data, onOpenVideoModal }: WhatsInItProps) {
  return (
    <section>
      <Container>
        <Card className={styles.inner}>
          <div className={styles.posterContainer} onClick={onOpenVideoModal}>
            <img width={330} src={pipe2} className={styles.pipe2} alt="Zamna" />
            <img width={lang === 'ar' ? 600 : 46} src={lang === 'ar' ? pipeAr : pipe} className={styles.pipe} alt="Zamna" />
            <div className={styles.posterContent}>
              <TextWrapper>
                <h3 className={styles.title}>
                  <FormattedMessage id="whats-in-it.h1" />
                </h3>
              </TextWrapper>
              <PlayWithTimer className={styles.playWithTime} time={data.duration} />
            </div>
            <img
              width={450}
              height={252}
              className={styles.poster}
              src={poster}
              alt="What's in it"
            />
          </div>
          <div className={styles.content}>
            <TextWrapper>
              <p className={styles.description}>
                <FormattedMessage
                  id="whats-in-it.description.1"
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </p>
              <p className={styles.description}>
                <FormattedMessage
                  id="whats-in-it.description.2"
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </p>
              <div className={styles.buttons}>
                <ShareButton url={data.url} title={data.title} />
                <Button variant="dark" onClick={onOpenVideoModal}>
                  <FormattedMessage id="constants.buttons.read-transcript.label" />
                </Button>
              </div>
            </TextWrapper>
          </div>
        </Card>
      </Container>
    </section>
  );
}