import { ReactNode } from 'react';
import styles from './Container.module.css';

type ContainerProps = {
  className?: string;
  children?: ReactNode;
}

export default function Container({ className, children }: ContainerProps) {
  return (
    <div className={`${styles.root} ${className ? className : ''}`}>
      {children}
    </div>
  );
}