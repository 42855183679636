import { FormattedMessage } from 'react-intl';
import { VideoData } from '../../../constants';
import Button from '../../Button';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import ShareButton from '../../ShareButton';
import TextWrapper from '../../TextWrapper';
import poster from '../../../assets/images/inform-poster.png';
import bg from '../../../assets/images/inform-bg.png';
import pipe from '../../../assets/images/pipes/pipe-5.svg';
import pipe2 from '../../../assets/images/pipes/pipe-6.svg';
import pipe3 from '../../../assets/images/pipes/pipe-7.svg';
import styles from './Inform.module.css';

type InformProps = {
  data: VideoData;
  onOpenVideoModal: () => void;
}

export default function Inform({ data, onOpenVideoModal }: InformProps) {
  return (
    <div className={styles.root}>
      <img className={styles.bg} width={1420} height={388} src={bg} alt="Zamna" />
      <Container>
        <div className={styles.inner}>
          <div className={styles.content}>
            <TextWrapper>
              <p className={styles.description}>
                <FormattedMessage id="inform.description" values={{
                  span: chunks => <span>{chunks}</span>,
                }} />
              </p>
              <ul className={styles.descriptionList}>
                <li>
                  <FormattedMessage
                    id="inform.description.list.1"
                    values={{
                      span: chunks => <span>{chunks}</span>,
                      b: chunks => <b>{chunks}</b>,
                      strong: chunks => <strong>{chunks}</strong>
                    }} />
                </li>
                <li>
                  <FormattedMessage
                    id="inform.description.list.2"
                    values={{
                      span: chunks => <span>{chunks}</span>,
                      b: chunks => <b>{chunks}</b>,
                      strong: chunks => <strong>{chunks}</strong>
                    }} />
                </li>
                <li>
                  <FormattedMessage
                    id="inform.description.list.3"
                    values={{
                      span: chunks => <span>{chunks}</span>,
                      b: chunks => <b>{chunks}</b>,
                      strong: chunks => <strong>{chunks}</strong>
                    }} />
                </li>
              </ul>
              <div className={styles.buttons}>
                <ShareButton url={data.url} title={data.title} />
                <Button variant="dark" onClick={onOpenVideoModal}>
                  <FormattedMessage id="constants.buttons.read-transcript.label" />
                </Button>
              </div>
            </TextWrapper>
          </div>
          <div className={styles.posterContainer} onClick={onOpenVideoModal}>
            <img height={165} src={pipe} className={styles.pipe} alt="" />
            <img width={45} src={pipe2} className={styles.pipe2} alt="" />
            <img height={165} src={pipe3} className={styles.pipe3} alt="" />
            <img
              width={455}
              height={252}
              className={styles.poster}
              src={poster}
              alt="What's in it"
            />
            <div className={styles.posterContent}>
              <TextWrapper>
                <h3 className={styles.title}>
                  <FormattedMessage id="inform.h1" />
                </h3>
              </TextWrapper>
              <PlayWithTimer className={styles.playWithTime} time={data.duration} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}