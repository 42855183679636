import { ReactElement, ReactNode } from 'react';
import styles from './Card.module.css';

type CardProps = {
  className?: string;
  children?: ReactNode | ReactElement;
}

export default function Card({ className, children }: CardProps) {
  return (
    <div className={`${styles.root} ${className && className}`}>
      {children}
    </div>
  )
}