import Dropdown, { ReactDropdownProps } from 'react-dropdown';
import 'react-dropdown/style.css';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import styles from './StyledDropdown.module.css';

export type StyledDropdownProps = {
  options: ReactDropdownProps['options'],
  value: ReactDropdownProps['value'],
  onChange: ReactDropdownProps['onChange'],
}

export default function StyledDropdown({ options, onChange, value }: StyledDropdownProps) {
  return (
    <Dropdown
      arrowClosed={<ChevronDownIcon className={styles.icon} />}
      arrowOpen={<ChevronDownIcon className={styles.iconRotated} />}
      options={options}
      className={styles.root}
      controlClassName={styles.control}
      menuClassName={styles.menu}
      placeholderClassName={styles.placeholder}
      onChange={onChange} value={value} />
  );
}