import "./BusinessWebsitePicker.css";

import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as PlaneIcon } from "../../../assets/icons/plane-blue-icon.svg";
import { ReactComponent as PaxIcon } from "../../../assets/icons/site-pax.svg";

interface Props {
  handleOpenStateChange?: (open: boolean) => void;
  open?: boolean;
}

export default function BusinessWebsitePicker(p: Props) {
  const [open, setOpen] = useState(false);

  const listRef = useRef<HTMLDivElement>(null);
  const openRef = useRef<boolean>(false);

  useEffect(outsideClickEffect, []);
  useEffect(() => {
    setOpen(p?.open || false);
  }, [p.open]);

  return (
    <div className="business-website-picker">
      <div
        className="business-picker-button"
        onClick={onItemClick}
        onTouchStart={onItemClick}
      >
        <div className="left-content">
          <PlaneIcon className="plane-icon"/>
          <div className="target-name">
            <FormattedMessage id="header.page-picker.companies" />
          </div>
        </div>
        <ChevronDown className="chevron-icon dimmed"/>
      </div>

      {open && (
        <div className="language-list" ref={listRef}>
          <div
            className="language-list-item"
            onClick={redirectToZamnaPassenger}
            onTouchEnd={redirectToZamnaPassenger}
          >
            <PaxIcon className="pax-icon"/>
            <div className="language-list-item-title">
              <FormattedMessage id="header.page-picker.passengers" />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function onItemClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    // p.handleOpenStateChange(true);
    setOpen(true);
  }

  function outsideClickEffect() {
    function handleClick(e: Event) {
      if (listRef.current === null) {
        return;
      }

      if (e.target === null) {
        return;
      }

      if (!listRef.current.contains(e.target as HTMLElement)) {
        setOpen(false);
        openRef.current = false;
      }
    }

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }

  function redirectToZamnaPassenger() {
    setOpen(false);
    const location = window.location;

    location.assign("https://www.zamna.com/pax/");
  }
}
