import { ComponentPropsWithRef, forwardRef, ReactNode } from 'react';
import styles from './Button.module.css';

export type ButtonProps = {
  children: ReactNode;
  className?: string;
  variant?: 'default' | 'dark';
} & ComponentPropsWithRef<'button'>

const Button = forwardRef(({
                             children,
                             className,
                             variant = 'default',
                             ...props
                           }: ButtonProps, ref: ComponentPropsWithRef<'button'>['ref']) => {
  return (
    <button ref={ref} {...props} className={`${styles.root} ${styles[variant]} ${className && className}`}>
      {children}
    </button>
  );
});

export default Button;