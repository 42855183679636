import { FormattedMessage } from 'react-intl';
import Container from '../Container';
import Socials from '../Socials';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import styles from './Contacts.module.css';
import TextWrapper from '../TextWrapper';

export default function Contacts() {
  return (
    <section className={styles.root}>
      <TextWrapper>
        <Container className={styles.inner}>
          <div className={styles.left}>
            <h2 className={styles.title}>
              <FormattedMessage id="contacts.title" />
            </h2>
          </div>
          <div className={styles.right}>
            <a href="mailto:@hello@zamna.com" className={styles.emailLink}>
              <SendIcon className={styles.sendIcon} />
              hello@zamna.com
            </a>
            <Socials />
          </div>
        </Container>
      </TextWrapper>
    </section>
  );
}