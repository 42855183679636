import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import messagesEn from './translations/en.json';
import messagesAr from './translations/ar.json';
import messagesEs from './translations/es.json';
import { LANGUAGE_PATH } from './constants';

const MESSAGES = {
  'en': messagesEn,
  'ar': messagesAr,
  'es': messagesEs,
};

export type MessagesType = keyof typeof MESSAGES;

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<MessagesType>('en');
  const lang = localStorage.getItem('language') as MessagesType || 'en';

  useEffect(() => {
    const path = pathname.replace('/', '') as MessagesType;

    if (pathname === '/') {
      navigate(`/${LANGUAGE_PATH[lang]}`);
    }

    if (Object.values(LANGUAGE_PATH).includes(path)) {
      let l = 'en' as MessagesType;
      Object.entries(LANGUAGE_PATH).forEach(([k, v]) => {
        if (v === path) {
          l = k as MessagesType;
        }
      });
      localStorage.setItem('language', l);
      setLanguage(l as MessagesType);
      navigate(`/${LANGUAGE_PATH[l]}`);
    }

    if (!Object.values(LANGUAGE_PATH).includes(path)) {
      navigate(`/${LANGUAGE_PATH[lang]}`);
      setLanguage(lang);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <IntlProvider locale={language} defaultLocale="en" messages={MESSAGES[language]}>
      <Routes>
        <Route
          element={<Home />}
          path="/:lang"
        />
      </Routes>
    </IntlProvider>
  );
}

export default App;
