import { ReactNode } from 'react';

type TextWrapperProps = {
  children: ReactNode;
}

export default function TextWrapper({ children }: TextWrapperProps) {
  const dirFromLocalStorage = localStorage.getItem('language') === 'ar' ? 'rtl' : 'ltr';

  return (
    <div dir={dirFromLocalStorage}>
      {children}
    </div>
  );
}