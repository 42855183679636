import { MessagesType } from './App';

export type VideoData = {
  id: string;
  title: string;
  url: string;
  duration: string;
  description: string;
}

type Videos = {
  [key in MessagesType]: Array<VideoData>
}

export const VIDEOS: Videos = {
  en: [
    {
      id: 'LGAMxvXWbxg',
      title: 'whats-in-it.h1',
      duration: '1:09 sec.',
      url: 'https://www.youtube.com/embed/LGAMxvXWbxg',
      description: 'youtube-video.0.description'
    },
    {
      id: 'zbGs0FlkDfA',
      title: 'remove-fines.h1',
      duration: '1:55 sec.',
      url: 'https://www.youtube.com/embed/zbGs0FlkDfA',
      description: 'youtube-video.1.description'
    },
    {
      id: 'fxSpvOY7Ew0',
      title: 'our-expertise.h1',
      duration: '1:47 sec.',
      url: 'https://www.youtube.com/embed/fxSpvOY7Ew0',
      description: 'youtube-video.2.description'
    },
    {
      id: 'NRi3fl1yLqw',
      title: 'inform.h1',
      duration: '0:51 sec.',
      url: 'https://www.youtube.com/embed/NRi3fl1yLqw',
      description: 'youtube-video.3.description'
    },
    {
      id: 'cermbqqelRw',
      title: 'constants.inform.label',
      duration: '1:00 sec.',
      url: 'https://www.youtube.com/embed/cermbqqelRw',
      description: 'youtube-video.4.description'
    },
    {
      id: 'oCha4uTTg-I',
      title: 'constants.verify.label',
      duration: '1:31 sec.',
      url: 'https://www.youtube.com/embed/oCha4uTTg-I',
      description: 'youtube-video.5.description'
    },
    {
      id: 'EkDBtSukZrA',
      title: 'approved.title',
      duration: '0:23 sec.',
      url: 'https://www.youtube.com/embed/EkDBtSukZrA',
      description: 'youtube-video.6.description'
    },
    {
      id: '1-ffQs_26Z4',
      title: 'constants.inform.label',
      duration: '0:53 sec.',
      url: 'https://www.youtube.com/embed/1-ffQs_26Z4',
      description: 'youtube-video.7.description'
    },
    {
      id: 'JOGsNmSM6fo',
      title: 'constants.verify.label',
      duration: '1:00 sec.',
      url: 'https://www.youtube.com/embed/JOGsNmSM6fo',
      description: 'youtube-video.8.description'
    },
    {
      id: 'KTIHReSHoJY',
      title: 'approved.title',
      duration: '0:23 sec.',
      url: 'https://www.youtube.com/embed/KTIHReSHoJY',
      description: 'youtube-video.9.description'
    },
    {
      id: 'T8XJT9E_iHs',
      title: 'constants.inform.label',
      duration: '0:42 sec.',
      url: 'https://www.youtube.com/embed/T8XJT9E_iHs',
      description: 'youtube-video.10.description'
    },
    {
      id: 'b145-Qv4DnE',
      title: 'constants.verify.label',
      duration: '2:47 sec.',
      url: 'https://www.youtube.com/embed/b145-Qv4DnE',
      description: 'youtube-video.11.description'
    },
    {
      id: 'dbjeqcUuL7s',
      title: 'approved.title',
      duration: '0:23 sec.',
      url: 'https://www.youtube.com/embed/dbjeqcUuL7s',
      description: 'youtube-video.12.description'
    },
    {
      id: 'pcZVx4XeJpk',
      title: 'results.title',
      duration: '1:48 sec.',
      url: 'https://www.youtube.com/embed/pcZVx4XeJpk',
      description: 'youtube-video.13.description'
    },
  ],
  ar: [
    {
      id: 'h30-NE0_Qwo',
      title: 'whats-in-it.h1',
      duration: '0:57 sec.',
      url: 'https://www.youtube.com/embed/h30-NE0_Qwo',
      description: 'youtube-video.0.description'
    },
    {
      id: 'O2u8pauJtq0',
      title: 'remove-fines.h1',
      duration: '2:28 sec.',
      url: 'https://www.youtube.com/embed/O2u8pauJtq0',
      description: 'youtube-video.1.description'
    },
    {
      id: 'LSwMeebptqM',
      title: 'our-expertise.h1',
      duration: '1:43 sec.',
      url: 'https://www.youtube.com/embed/LSwMeebptqM',
      description: 'youtube-video.2.description'
    },
    {
      id: 'V65T8MilcBs',
      title: 'inform.h1',
      duration: '0:51 sec.',
      url: 'https://www.youtube.com/embed/V65T8MilcBs',
      description: 'youtube-video.3.description'
    },
    {
      id: 'HyeBqrH-F8o',
      title: 'constants.inform.label',
      duration: '0:53 sec.',
      url: 'https://www.youtube.com/embed/HyeBqrH-F8o',
      description: 'youtube-video.4.description'
    },
    {
      id: 'DcwsKfTBYH8',
      title: 'constants.verify.label',
      duration: '1:20 sec.',
      url: 'https://www.youtube.com/embed/DcwsKfTBYH8',
      description: 'youtube-video.5.description'
    },
    {
      id: 'HwalI_ZI6fg',
      title: 'approved.title',
      duration: '0:23 sec.',
      url: 'https://www.youtube.com/embed/HwalI_ZI6fg',
      description: 'youtube-video.6.description'
    },
    {
      id: '_2cCrXZsu5g',
      title: 'constants.inform.label',
      duration: '0:45 sec.',
      url: 'https://www.youtube.com/embed/_2cCrXZsu5g',
      description: 'youtube-video.7.description'
    },
    {
      id: 'aWIpRf8DBBE',
      title: 'constants.verify.label',
      duration: '1:04 sec.',
      url: 'https://www.youtube.com/embed/aWIpRf8DBBE',
      description: 'youtube-video.8.description'
    },
    {
      id: 'UsR2KiVdLDQ',
      title: 'approved.title',
      duration: '0:23 sec.',
      url: 'https://www.youtube.com/embed/UsR2KiVdLDQ',
      description: 'youtube-video.9.description'
    },
    {
      id: 'UNXDjpJMtM8',
      title: 'constants.inform.label',
      duration: '0:41 sec.',
      url: 'https://www.youtube.com/embed/UNXDjpJMtM8',
      description: 'youtube-video.10.description'
    },
    {
      id: 'mhDC4FDSId4',
      title: 'constants.verify.label',
      duration: '1:17 sec.',
      url: 'https://www.youtube.com/embed/mhDC4FDSId4',
      description: 'youtube-video.11.description'
    },
    {
      id: 'HwalI_ZI6fg',
      title: 'approved.title',
      duration: '0:23 sec.',
      url: 'https://www.youtube.com/embed/HwalI_ZI6fg',
      description: 'youtube-video.12.description'
    },
    {
      id: '14C4Dqt4w4w',
      title: 'results.title',
      duration: '1:13 sec.',
      url: 'https://www.youtube.com/embed/14C4Dqt4w4w',
      description: 'youtube-video.13.description'
    },
  ],
  es: [
    {
      id: 'mRqlzDiKNmE',
      title: 'whats-in-it.h1',
      duration: '1:22 sec.',
      url: 'https://www.youtube.com/embed/mRqlzDiKNmE',
      description: 'youtube-video.0.description'
    },
    {
      id: 'o8ey8neJlj0',
      title: 'remove-fines.h1',
      duration: '2:21 sec.',
      url: 'https://www.youtube.com/embed/o8ey8neJlj0',
      description: 'youtube-video.1.description'
    },
    {
      id: 'RfPObuAIG4U',
      title: 'our-expertise.h1',
      duration: '2:38 sec.',
      url: 'https://www.youtube.com/embed/RfPObuAIG4U',
      description: 'youtube-video.2.description'
    },
    {
      id: 'cISuglIGj-o',
      title: 'inform.h1',
      duration: '0:51 sec.',
      url: 'https://www.youtube.com/embed/cISuglIGj-o',
      description: 'youtube-video.3.description'
    },
    {
      id: 'mtEAsJ3DfRw',
      title: 'constants.inform.label',
      duration: '0:59 sec.',
      url: 'https://www.youtube.com/embed/mtEAsJ3DfRw',
      description: 'youtube-video.4.description'
    },
    {
      id: '9IK7ydTPhOo',
      title: 'constants.verify.label',
      duration: '1:20 sec.',
      url: 'https://www.youtube.com/embed/9IK7ydTPhOo',
      description: 'youtube-video.5.description'
    },
    {
      id: '61j9Dk_Ulug',
      title: 'approved.title',
      duration: '0:21 sec.',
      url: 'https://www.youtube.com/embed/61j9Dk_Ulug',
      description: 'youtube-video.6.description'
    },
    {
      id: 'RIfcaxIvo0I',
      title: 'constants.inform.label',
      duration: '0:48 sec.',
      url: 'https://www.youtube.com/embed/RIfcaxIvo0I',
      description: 'youtube-video.7.description'
    },
    {
      id: 'fbtyo7T5Jlg',
      title: 'constants.verify.label',
      duration: '1:04 sec.',
      url: 'https://www.youtube.com/embed/fbtyo7T5Jlg',
      description: 'youtube-video.8.description'
    },
    {
      id: 'uMo5lE3lYRk',
      title: 'approved.title',
      duration: '0:21 sec.',
      url: 'https://www.youtube.com/embed/uMo5lE3lYRk',
      description: 'youtube-video.9.description'
    },
    {
      id: 'hICmXVkMprM',
      title: 'constants.inform.label',
      duration: '0:50 sec.',
      url: 'https://www.youtube.com/embed/hICmXVkMprM',
      description: 'youtube-video.10.description'
    },
    {
      id: 'N9prDyUB9ok',
      title: 'constants.verify.label',
      duration: '2:30 sec.',
      url: 'https://www.youtube.com/embed/N9prDyUB9ok',
      description: 'youtube-video.11.description'
    },
    {
      id: 'E0mKs3ZXe4E',
      title: 'approved.title',
      duration: '0:21 sec.',
      url: 'https://www.youtube.com/embed/E0mKs3ZXe4E',
      description: 'youtube-video.12.description'
    },
    {
      id: 'cKS7cPsrVMI',
      title: 'results.title',
      duration: '2:31 sec.',
      url: 'https://www.youtube.com/embed/cKS7cPsrVMI',
      description: 'youtube-video.13.description'
    },
  ],
};

export const LANGUAGE_PATH: {
  [keys in MessagesType]: string;
} = {
  'en': 'English',
  'ar': 'Arabic',
  'es': 'Spanish',
}