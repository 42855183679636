import { useNavigate } from 'react-router-dom';
import { UIEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LANGUAGE_PATH, VIDEOS } from '../constants';
import { MessagesType } from '../App';
import Header from '../components/Header';
import Container from '../components/Container';
import TextWrapper from '../components/TextWrapper';
import WhatsInIt from '../components/sections/WhatsInIt';
import RemoveFines from '../components/sections/RemoveFines';
import OurExpertise from '../components/sections/OurExpertise';
import Inform from '../components/sections/Inform';
import LiveDemos from '../components/sections/LiveDemos';
import ApprovedDCS from '../components/sections/ApprovedDCS';
import Results from '../components/sections/Results';
import Contacts from '../components/Contacts';
import LiveDemosButton from '../components/LiveDemosButton';
import Footer from '../components/Footer';
import VideoModal from '../components/VideoModal';
import gradient from '../assets/images/top-gradient.png';
import pipeMobile from '../assets/images/pipes/pipe-mobile.svg';
import styles from './Home.module.css';

export default function Home() {
  const navigate = useNavigate();
  const liveDemosRef = useRef<HTMLDivElement>(null);
  const [language, setLanguage] = useState<MessagesType>('en');
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [demosOffsetTop, setDemosOffsetTop] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const lang = localStorage.getItem('language') as MessagesType;
  const videos = VIDEOS[language];

  useEffect(() => {
    if (lang) {
      setLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    if (liveDemosRef.current) {
      setDemosOffsetTop(liveDemosRef.current.offsetTop);
    }
  }, [liveDemosRef]);

  const handleChangeLanguage = (lang: MessagesType) => {
    setLanguage(lang);
    navigate(`/${LANGUAGE_PATH[lang]}`)
    localStorage.setItem('language', lang);
  };

  const handleCloseModal = () => setOpen(false);

  const handleNextVideo = () => {
    if (activeVideo < VIDEOS[language].length - 1) {
      setActiveVideo(prev => prev + 1);
    }
  };

  const handleOpenModal = (index: number) => {
    return () => {
      setActiveVideo(index);
      setOpen(true);
    };
  };

  const handlePrevVideo = () => {
    if (activeVideo > 0) {
      setActiveVideo(prev => prev - 1);
    }
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollTop = e.currentTarget.scrollTop;
    setScrollTop(scrollTop);
  };

  const handleScrollToDemos = () => {
    liveDemosRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className={`${styles.wrapper} ${language}Lang`} onScroll={handleScroll}>
        <Header language={language} onChangeLang={handleChangeLanguage} />
        <main className={styles.root}>
          <img width={1440} src={gradient} className={styles.topGradient} alt="Zamna" />
          <img width={43.5} src={pipeMobile} className={styles.pipeMobile} alt="Zamna" />
          <Container>
            <TextWrapper>
              <h1 className={styles.mainTitle}>
                <FormattedMessage
                  id="h1"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </h1>
            </TextWrapper>
          </Container>
          <WhatsInIt lang={language} data={videos[0]} onOpenVideoModal={handleOpenModal(0)} />
          <RemoveFines data={videos[1]} onOpenVideoModal={handleOpenModal(1)} />
          <OurExpertise data={videos[2]} onOpenVideoModal={handleOpenModal(2)} />
          <Inform data={videos[3]} onOpenVideoModal={handleOpenModal(3)} />
          <LiveDemos lang={language} onOpenVideoModal={handleOpenModal} ref={liveDemosRef} />
          <ApprovedDCS data={videos[12]} onOpenVideoModal={handleOpenModal(12)} />
          <Results data={videos[13]} onOpenVideoModal={handleOpenModal(13)} />
          <Contacts />
          {demosOffsetTop > scrollTop && (
            <LiveDemosButton className={scrollTop < 50 ? styles.demoButtonTop : ''}
                             onClick={handleScrollToDemos} />
          )}
        </main>
        <Footer />
      </div>
      <VideoModal
        open={open}
        lang={language}
        index={activeVideo}
        onClose={handleCloseModal}
        onNext={handleNextVideo}
        onPrev={handlePrevVideo}
      />
    </>
  )
}