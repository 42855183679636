import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Popup from 'reactjs-popup';
import { VIDEOS } from '../../constants';
import { MessagesType } from '../../App';
import ShareButton from '../ShareButton';
import TextWrapper from '../TextWrapper';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import styles from './VideoModal.module.css';

type VideoModalProps = {
  index: number;
  open: boolean;
  lang: MessagesType;
  onClose: () => void;
  onPrev: () => void;
  onNext: () => void;
}

const contentStyle = { padding: 0 };

export default function VideoModal({
                                     index,
                                     open,
                                     lang,
                                     onClose,
                                     onPrev,
                                     onNext
                                   }: VideoModalProps) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const data = VIDEOS[lang][index];
  const prevData = VIDEOS[lang][index - 1];
  const nextData = VIDEOS[lang][index + 1];

  return (
    <Popup
      open={open}
      onClose={onClose}
      closeOnDocumentClick={false}
      {...{ contentStyle }}
    >
      <button onClick={onClose} className={styles.close}>
        <CloseIcon />
      </button>
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.videoResponsive}>
            <iframe
              width="853"
              height="480"
              src={`${data.url}?autoplay=1&rel=0`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <div className={styles.videoDescription}>
            <TextWrapper>
              <div className={styles.videoDescriptionHeader}>
                <div className={styles.videoDescriptionTitleContainer}>
                  <h5 className={styles.videoDescriptionTitle}>
                    <FormattedMessage id={data.title} />
                  </h5>
                  <time dir="ltr" className={styles.videoDescriptionTime}>{data?.duration}</time>
                </div>
                <ShareButton url={data.url} title={data.title} />
              </div>
            </TextWrapper>
            <div className={styles.mobileTabs}>
              <div
                className={`${styles.mobileTabItem} ${activeTab === 0 && styles.mobileTabItemActive}`}
                onClick={() => setActiveTab(0)}
              >
                <FormattedMessage id="constants.about.label" />
              </div>
              <div
                className={`${styles.mobileTabItem} ${activeTab === 1 && styles.mobileTabItemActive}`}
                onClick={() => setActiveTab(1)}
              >
                <FormattedMessage id="constants.transcript.label" />
              </div>
            </div>
            <TextWrapper>
              <div
                className={`${styles.videoDescriptionBody} ${activeTab === 0 && styles.visibleTab}`}>
                <FormattedMessage
                  id={data.description}
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    span: chunks => <span>{chunks}</span>,
                    strong: chunks => <strong>{chunks}</strong>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <li>{chunks}</li>
                  }}
                />
              </div>
            </TextWrapper>
          </div>
        </div>
        <div className={`${styles.right} ${activeTab === 1 && styles.visibleTab}`}>
          <TextWrapper>
            <h5 className={styles.transcriptTitle}>
              <FormattedMessage id="constants.transcript.label" />
            </h5>
            <div className={styles.transcriptList}>
              <FormattedMessage
                id={`youtube-video.${index}.transcript`}
                values={{
                  time: chunks => <time>{chunks}</time>,
                  span: chunks => <span>{chunks}</span>
                }}
              />
            </div>
          </TextWrapper>
        </div>
        <div className={styles.actions}>
          {index > 0 && (
            <button
              className={`${styles.button} ${index > 0 && styles.buttonActive}`}
              onClick={onPrev}
            >
              <ChevronLeftIcon />
              <FormattedMessage id={prevData.title} /> ({prevData.duration})
            </button>
          )}
          {index < VIDEOS[lang].length - 1 && (
            <button
              className={`${styles.button} ${index < VIDEOS[lang].length - 1 && styles.buttonActive}`}
              onClick={onNext}>
              <FormattedMessage id={nextData.title} /> ({nextData.duration})
              <ChevronRightIcon />
            </button>
          )}
        </div>
      </div>
    </Popup>
  );
}