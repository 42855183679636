import { FormattedMessage } from 'react-intl';
import { VideoData } from '../../../constants';
import Button from '../../Button';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import ShareButton from '../../ShareButton';
import TextWrapper from '../../TextWrapper';
import poster from '../../../assets/images/our-expertise-poster.png';
import pipe from '../../../assets/images/pipes/pipe-4.svg';
import styles from './OurExpertise.module.css';

type OurExpertiseProps = {
  data: VideoData;
  onOpenVideoModal: () => void;
}

export default function OurExpertise({ data, onOpenVideoModal }: OurExpertiseProps) {
  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.posterContainer} onClick={onOpenVideoModal}>
            <img width={330} src={pipe} className={styles.pipe} alt="" />
            <img
              width={456}
              height={270}
              className={styles.poster}
              src={poster}
              alt="What's in it"
            />
            <div className={styles.posterContent}>
              <TextWrapper>
                <h3 className={styles.title}>
                  <FormattedMessage id="our-expertise.h1" />
                </h3>
              </TextWrapper>
              <PlayWithTimer className={styles.playWithTime} time={data.duration} />
            </div>
          </div>
          <div className={styles.content}>
            <TextWrapper>
              <p className={styles.description}>
                <FormattedMessage
                  id="our-expertise.description.1"
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </p>
              <p className={styles.description}>
                <FormattedMessage
                  id="our-expertise.description.2"
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="our-expertise.description.3"
                  values={{
                    a: chunks => <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.zamna.com#client"
                                    className="text-underline">{chunks}</a>
                  }}
                />
              </p>
              <div className={styles.buttons}>
                <ShareButton url={data.url} title={data.title} />
                <Button variant="dark" onClick={onOpenVideoModal}>
                  <FormattedMessage id="constants.buttons.read-transcript.label" />
                </Button>
              </div>
            </TextWrapper>
          </div>
        </div>
      </Container>
    </section>
  );
}