import { FormattedMessage } from 'react-intl';
import { ComponentPropsWithoutRef } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import styles from './LiveDemosButton.module.css';

export default function LiveDemosButton({ className, ...props }: ComponentPropsWithoutRef<'button'>) {
  return (
    <button className={`${styles.root} ${className && className}`} {...props}>
      <ArrowDownIcon />
      <FormattedMessage id="constants.go-to-live-demo.label" />
    </button>
  );
}