import { FormattedMessage } from 'react-intl';
import { VideoData } from '../../../constants';
import Button from '../../Button';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import ShareButton from '../../ShareButton';
import TextWrapper from '../../TextWrapper';
import poster from '../../../assets/images/remove-fines-poster.png';
import pipe from '../../../assets/images/pipes/pipe-3.svg';
import styles from './RemoveFines.module.css';

type RemoveFinesProps = {
  data: VideoData;
  onOpenVideoModal: () => void;
}

export default function RemoveFines({ data, onOpenVideoModal }: RemoveFinesProps) {
  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.content}>
            <TextWrapper>
              <p className={styles.description}>
                <FormattedMessage
                  id="remove-fines.description.1"
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </p>
              <div className={styles.buttons}>
                <ShareButton url={data.url} title={data.title} />
                <Button variant="dark" onClick={onOpenVideoModal}>
                  <FormattedMessage id="constants.buttons.read-transcript.label" />
                </Button>
              </div>
            </TextWrapper>
          </div>
          <div className={styles.posterContainer} onClick={onOpenVideoModal}>
            <img width={330} src={pipe} className={styles.pipe} alt="" />
            <img
              width={456}
              height={270}
              className={styles.poster}
              src={poster}
              alt="What's in it"
            />
            <div className={styles.posterContent}>
              <TextWrapper>
                <h3 className={styles.title}>
                  <FormattedMessage id="remove-fines.h1" />
                </h3>
              </TextWrapper>
              <PlayWithTimer className={styles.playWithTime} time={data.duration} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}