import { ComponentPropsWithRef, forwardRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { VIDEOS } from '../../../constants';
import { MessagesType } from '../../../App';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import TextWrapper from '../../TextWrapper';
import { ReactComponent as CursorClickIcon } from '../../../assets/icons/cursor-click.svg';
import demo1inform from '../../../assets/images/demo-1-poster-inform.png';
import demo1verify from '../../../assets/images/demo-1-poster-verify.png';
import demo2inform from '../../../assets/images/demo-2-poster-inform.png';
import demo2verify from '../../../assets/images/demo-2-poster-verify.png';
import demo3inform from '../../../assets/images/demo-3-poster-inform.png';
import demo3verify from '../../../assets/images/demo-3-poster-verify.png';
import pipe1 from '../../../assets/images/pipes/pipe-8.svg';
import pipe2 from '../../../assets/images/pipes/pipe-9.svg';
import pipe3 from '../../../assets/images/pipes/pipe-10.svg';
import styles from './LiveDemos.module.css';

const posters: Array<Array<string>> = [
  [demo1inform, demo1verify],
  [demo2inform, demo2verify],
  [demo3inform, demo3verify]
];

const pipes = [pipe1, pipe2, pipe3];

type LiveDemosProps = {
  lang: MessagesType;
  onOpenVideoModal: (index: number) => () => void;
}

const LiveDemos = forwardRef(({
                                lang,
                                onOpenVideoModal
                              }: LiveDemosProps, ref: ComponentPropsWithRef<'section'>['ref']) => {
  const intl = useIntl();
  const DEMOS_ORDER_ARRAY = lang === 'ar' ? [2, 1, 0] : [0, 1, 2];
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleSetActiveTab = (active: number) => {
    return () => {
      setActiveTab(active);
    };
  };

  return (
    <section ref={ref}>
      <div className={styles.tabs}>
        <div className={styles.tabsHeader}>
          <CursorClickIcon className={styles.icon} />
          <FormattedMessage
            id="tabs.title.label"
            values={{
              span: chunks => <span>{chunks}</span>
            }}
          />
        </div>
        <TextWrapper>
          <div className={styles.tabsList}>
            <div
              className={`${styles.tabItem} ${activeTab === 0 && styles.tabItemActive}`}
              onClick={handleSetActiveTab(0)}
            >
              <span className={styles.tabItemLetter}>A</span>
              <FormattedMessage id="tabs.tab.1" />
            </div>
            <div
              className={`${styles.tabItem} ${activeTab === 1 && styles.tabItemActive}`}
              onClick={handleSetActiveTab(1)}
            >
              <span className={styles.tabItemLetter}>B</span>
              <FormattedMessage id="tabs.tab.2" />
            </div>
            <div
              className={`${styles.tabItem} ${activeTab === 2 && styles.tabItemActive}`}
              onClick={handleSetActiveTab(2)}
            >
              <span className={styles.tabItemLetter}>C</span>
              <FormattedMessage id="tabs.tab.3" />
            </div>
          </div>
        </TextWrapper>
      </div>
      <Container>
        <div className={styles.inner}>
          {DEMOS_ORDER_ARRAY.map((i) => {
            const tags = intl.messages[`live-demo.${i + 1}.tags.1`] as string;
            const tags2 = intl.messages[`live-demo.${i + 1}.tags.2`] as string;

            return (
              <div className={`${styles.item} ${activeTab === i && styles.itemMobile}`} key={i}>
                <img src={pipes[i]} width={45} className={styles.pipe} alt="" />
                <h3 className={styles.itemTitle}>
                  <FormattedMessage id={`live-demo.${i + 1}.title`} />
                </h3>
                <p className={styles.itemFootnote}>
                  <FormattedMessage
                    id={`live-demo.${i + 1}.footnote`}
                    values={{
                      span: chunks => <span>{chunks}</span>,
                    }}
                  />
                </p>
                <p className={styles.itemFootnote}>
                  <FormattedMessage
                    id="constants.powered-by.label"
                  /> Zamna
                </p>
                <div className={`${styles.itemTags} ${styles.itemTagsCentered}`}>
                  {tags?.split(',').map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </div>
                <div className={styles.itemPosterContainer} onClick={onOpenVideoModal(4 + i * 3)}>
                  <div className={styles.itemPosterInner}>
                    <h4 className={styles.itemPosterTitle}>
                      <FormattedMessage id="constants.inform.label" />
                    </h4>
                    <PlayWithTimer time={VIDEOS[lang][4 + i * 3].duration} />
                  </div>
                  <img
                    src={posters[i]?.[0]}
                    className={styles.itemPoster}
                    width={400}
                    height={200}
                    alt="Zamna"
                  />
                </div>
                <TextWrapper>
                  <p className={styles.itemDescription}>
                    <FormattedMessage
                      id={`live-demo.${i + 1}.description.1`}
                      values={{
                        span: chunks => <span>{chunks}</span>,
                        b: chunks => <b>{chunks}</b>,
                        strong: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  </p>
                </TextWrapper>
                <div className={`${styles.itemTags} ${styles.itemTagsSmall}`}>
                  {tags2?.split(',').map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </div>
                <div className={styles.itemPosterContainer} onClick={onOpenVideoModal(5 + i * 3)}>
                  <div className={styles.itemPosterInner}>
                    <h4 className={styles.itemPosterTitle}>
                      <FormattedMessage id="constants.verify.label" />
                    </h4>
                    <PlayWithTimer time={VIDEOS[lang][5 + i * 3].duration} />
                  </div>
                  <img
                    src={posters[i]?.[1]}
                    className={styles.itemPoster}
                    width={400}
                    height={200}
                    alt="Zamna"
                  />
                </div>
                <TextWrapper>
                  <p className={styles.itemDescription}>
                    <FormattedMessage
                      id={`live-demo.${i + 1}.description.2`}
                      values={{
                        span: chunks => <span>{chunks}</span>,
                        b: chunks => <b>{chunks}</b>,
                        strong: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  </p>
                </TextWrapper>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
});

export default LiveDemos;
