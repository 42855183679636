import { FormattedMessage } from 'react-intl';
import { VideoData } from '../../../constants';
import Button from '../../Button';
import Card from '../../Card';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import ShareButton from '../../ShareButton';
import TextWrapper from '../../TextWrapper';
import poster from '../../../assets/images/results-poster.png';
import styles from './Results.module.css';

type ResultsProps = {
  data: VideoData;
  onOpenVideoModal: () => void
}

export default function Results({ data, onOpenVideoModal }: ResultsProps) {
  return (
    <section className={styles.root}>
      <Container>
        <Card className={styles.inner}>
          <div className={styles.content}>
            <TextWrapper>
              <ul className={styles.descriptionList}>
                <li>
                  <FormattedMessage
                    id="results.list.1"
                    values={{
                      b: chunks => <b>{chunks}</b>,
                      strong: chunks => <strong>{chunks}</strong>,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="results.list.2"
                    values={{
                      b: chunks => <b>{chunks}</b>,
                      strong: chunks => <strong>{chunks}</strong>,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="results.list.3"
                    values={{
                      b: chunks => <b>{chunks}</b>,
                      strong: chunks => <strong>{chunks}</strong>,
                    }}
                  />
                </li>
              </ul>
              <p className={styles.description}>
                <FormattedMessage
                  id="results.description"
                  values={{
                    span: chunks => <span>{chunks}</span>,
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </p>
              <div className={styles.buttons}>
                <ShareButton url={data.url} title={data.title} />
                <Button variant="dark" onClick={onOpenVideoModal}>
                  <FormattedMessage id="constants.buttons.read-transcript.label" />
                </Button>
              </div>
            </TextWrapper>
          </div>
          <div className={styles.posterContainer} onClick={onOpenVideoModal}>
            <div className={styles.posterInner}>
              <h3 className={styles.title}>
                <FormattedMessage id="results.title" />
              </h3>
              <PlayWithTimer className={styles.playWithTime} time={data.duration} />
            </div>
            <img
              width={456}
              height={270}
              className={styles.poster}
              src={poster}
              alt="What's in it"
            />
          </div>
        </Card>
      </Container>
    </section>
  );
}