import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as MediumIcon } from '../../assets/icons/medium.svg';
import styles from './Socials.module.css';

type SocialsTypes = {
  className?: string;
}

export default function Socials({ className }: SocialsTypes) {
  return (
    <div dir="ltr" className={`${styles.root} ${className && className}`}>
      <a
        href="https://www.linkedin.com/company/zamna/"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <LinkedinIcon />
        linkedin
      </a>
      <a
        href="https://zamna.medium.com/"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <MediumIcon />
        medium
      </a>
    </div>
  )
}