import { FormattedMessage } from 'react-intl';
import { VideoData } from '../../../constants';
import Button from '../../Button';
import Container from '../../Container';
import PlayWithTimer from '../../PlayWithTimer';
import ShareButton from '../../ShareButton';
import TextWrapper from '../../TextWrapper';
import poster from '../../../assets/images/approve-poster.png';
import pipe from '../../../assets/images/pipes/pipe-11.svg';
import pipe2 from '../../../assets/images/pipes/pipe-12.svg';
import pipe3 from '../../../assets/images/pipes/pipe-13.svg';
import pipe4 from '../../../assets/images/pipes/pipe-14.svg';
import styles from './ApprovedDCS.module.css';

type ApprovedDCSProps = {
  data: VideoData;
  onOpenVideoModal: () => void
}

export default function ApprovedDCS({ data, onOpenVideoModal }: ApprovedDCSProps) {
  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.posterContainer} onClick={onOpenVideoModal}>
            <img height={165} src={pipe} className={styles.pipe} alt="" />
            <img width={45} src={pipe2} className={styles.pipe2} alt="" />
            <img height={165} src={pipe3} className={styles.pipe3} alt="" />
            <img height={210} src={pipe4} className={styles.pipe4} alt="" />
            <div className={styles.posterInner}>
              <h3 className={styles.title}>
                <FormattedMessage id="approved.title" />
              </h3>
              <PlayWithTimer className={styles.playWithTime} time={data.duration} />
            </div>
            <img
              width={456}
              height={270}
              className={styles.poster}
              src={poster}
              alt="What's in it"
            />
          </div>
          <div className={styles.content}>
            <TextWrapper>
              <p className={styles.description}>
                <FormattedMessage
                  id="approved.description"
                  values={{
                    span: chunks => <span>{chunks}</span>,
                    b: chunks => <b>{chunks}</b>,
                    strong: chunks => <strong>{chunks}</strong>
                  }} />
              </p>
              <div className={styles.buttons}>
                <ShareButton url={data.url} title={data.title} />
                <Button variant="dark" onClick={onOpenVideoModal}>
                  <FormattedMessage id="constants.buttons.read-transcript.label" />
                </Button>
              </div>
            </TextWrapper>
          </div>
        </div>
      </Container>
    </section>
  );
}