import { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { MessagesType } from "../../App";
import { ReactComponent as BurgerIcon } from "../../assets/icons/burger-icon.svg";
import { ReactComponent as CloseBurgerIcon } from "../../assets/icons/close-burger-icon.svg";
import {
    ReactComponent as LiveDemoButtonIcon
} from "../../assets/icons/live-demo-button-header-icon.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as WWWIcon } from "../../assets/icons/www.svg";
import Config from "../../config";
import Container from "../Container";
import StyledDropdown from "../StyledDropdown";
import styles from "./Header.module.css";
import BusinessWebsitePicker from "./website-picker/BusinessWebsitePicker";

const LANGUAGES_OPTIONS: Array<{
  value: MessagesType;
  label: ReactNode;
}> = [
  {
    value: "en",
    label: (
      <>
        <WWWIcon width={24} height={20} className={styles.icon} /> En
      </>
    ),
  },
  {
    value: "ar",
    label: (
      <>
        <WWWIcon width={24} height={20} className={styles.icon} /> Ar
      </>
    ),
  },
  {
    value: "es",
    label: (
      <>
        <WWWIcon width={24} height={20} className={styles.icon} /> Es
      </>
    ),
  },
];

type HeaderProps = {
  language: MessagesType;
  onChangeLang: (lang: MessagesType) => void;
};

export default function Header({ language, onChangeLang }: HeaderProps) {
  const [burgerOpen, setBurgerOpen] = useState(false);

  return (
    <header className={`${styles.root} ${burgerOpen ? styles.fullscreen : ""}`}>
      <Container>
        <div className={styles.inner}>
          <a
            href={Config.zamnaWebSiteInLanguage(language)}
            className={styles.link}
          >
            <Logo className={styles.logo} />
          </a>
          <div className={`${styles.navigation} ${burgerOpen ? styles.visible : ""}`} >
            <button
              onClick={() => setBurgerOpen(false)}
              className={styles.demoButton}
            >
              <LiveDemoButtonIcon className={styles.demoButtonIcon}/>
              <FormattedMessage id="demo-page-button" />
            </button>
            <div className={styles.separator} />
              <BusinessWebsitePicker/>
              <StyledDropdown
                options={LANGUAGES_OPTIONS}
                value={LANGUAGES_OPTIONS.find((i) => i.value === language)}
                onChange={(e) => onChangeLang(e.value as MessagesType)}
              />
            </div>
          </div>
        <button
          className={styles.burgerButton}
          onClick={() => {
            setBurgerOpen((v) => !v);
          }}
        >
          {burgerOpen ? <CloseBurgerIcon/> : <BurgerIcon/>}
        </button>
      </Container>
    </header>
  );
}
