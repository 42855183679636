import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import styles from './PlayWithTimer.module.css';

type PlayWithTimerProps = {
  className?: string;
  time: string;
  onClick?: () => void;
}

export default function PlayWithTimer({ className, time, onClick }: PlayWithTimerProps) {
  return (
    <div dir="ltr" className={`${styles.root} ${className && className}`} onClick={onClick}>
      <PlayIcon className={styles.icon} />
      <span>{time}</span>
    </div>
  );
}