import { FormattedMessage } from 'react-intl';
import Container from '../Container';
import Socials from '../Socials';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <footer className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <Logo className={styles.logo} />
          <Socials className={styles.socials} />
          <p className={styles.copyright}>
            <FormattedMessage id="footer.copyright" />
          </p>
          <a
            href="https://www.zamna.com/privacy-notice/"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.footnote}
          >
            <FormattedMessage id="footer.privacy" />
          </a>
        </div>
      </Container>
    </footer>
  )
}